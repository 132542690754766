<template>
    <el-dialog title="随访历史记录" :visible.sync="open" :close-on-click-modal="true" width="700px">
        <template #default>
            <div class="followBox">
                <el-table border :data="list" height="400" style="width: 100%">
                    <el-table-column align="center" type="index" width="50" label="序号" />
                    <el-table-column align="center" label="状态" width="140">
                        <template slot-scope="scope">
                            <span>{{ getCurrentDateInYYYYMMDD(scope.row.nextVisitTime)  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="visitTime" align="center" label="随访时间" width="140">
                        <template slot-scope="scope">
                            {{ formatDateToYYYYMMDD(scope.row.nextVisitTime) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="visitRecommend" align="center" label="备注" width="328" show-overflow-tooltip>

                    </el-table-column>
                </el-table>
            </div>
        </template>

    </el-dialog>
</template>
<script>
export default {
    name: 'follow-records',
    props: {
        value: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        list: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    computed: {
        open: {
            get() {
                return this.value
            },
            set(v) {
                console.log(v);
                this.$emit("input", v)
            },
        },
    },
    methods: {
        formatDateToYYYYMMDD(time) {
            const date = new Date(time);

            // 提取年、月、日
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要加1
            const day = String(date.getDate()).padStart(2, '0');

            // 格式化为 YYYY-MM-DD
            return `${year}-${month}-${day}`;
        },
        getCurrentDateInYYYYMMDD(visitTime) {
            const today = new Date();

            // 提取年、月、日
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要加1
            const day = String(today.getDate()).padStart(2, '0');

            // 格式化为 YYYY-MM-DD
            let time= `${year}-${month}-${day}`;

            // return time>this.formatDateToYYYYMMDD(visitTime)?'1':'0'
            if(time>this.formatDateToYYYYMMDD(visitTime)){
                return '已完成'
            }else if(time<this.formatDateToYYYYMMDD(visitTime)){
                return '等待执行'

            }else if(time==this.formatDateToYYYYMMDD(visitTime)){
                return '执行中'

            }else{
                return ''
            }


            
        }

    }
}
</script>
<style lang="scss" scoped>
@import "@/style/TestKit/TestKitDialog.scss";

.followBox {
    min-height: 310px;
    padding: 20px;

}
</style>