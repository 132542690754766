<template>
  <div class="TestKitList">
    <div class="form">
      <el-form
        ref="formRef"
        :inline="true"
        label-width="100px"
        :model="form"
        @submit.native.prevent
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" prop="username">
              <el-input
                v-model="form.username"
                clearable
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                clearable
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="idCardNo">
              <el-input
                v-model="form.idCardNo"
                clearable
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="管理医生" prop="identify">
              <el-select v-model="form.identify" clearable placeholder="请选择">
                <el-option
                  v-for="item in doctor_list"
                  :key="item.id"
                  :label="item.docName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="随访时间" prop="identify">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.nextVisitTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="8">
            <el-form-item label="患者状态" prop="patientStatus">
              <el-select v-model="form.patientStatus" clearable placeholder="请选择">
                <el-option
                    v-for="item in patient_status"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>-->
          <el-col :span="24">
            <el-form-item class="TestKitList-form-button">
              <el-button
                @click="onSubmit(form)"
                type="primary"
                native-type="submit"
                >查询</el-button
              >
              <el-button @click="Reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list">
      <div class="TestKitList-list-handle">
        <!-- <el-button type="primary" @click="add" size="small">添加</el-button> -->
        <!--        <el-button type="primary" @click="add" size="small" >导出全部数据</el-button>-->
        <!--        <el-button type="primary" @click="add" size="small" >下载问卷内容模板</el-button>-->
        <!--        <el-button type="primary" @click="add" size="small" >导入问卷内容</el-button>-->
      </div>
      <el-table
        :data="table"
        v-loading="tableLoad"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
      >
        <el-table-column prop="patientName" align="center" label="姓名">
          <!-- <template slot-scope="scope">
            <span class="TestKitList-list-blue" @click="detail(scope.row)">{{ scope.row.patientName }}</span>
          </template> -->
        </el-table-column>
        <el-table-column align="center" label="随访时间">
          <template slot-scope="scope">
            <el-link
              @click="goSet(scope.row)"
              v-if="scope.row.visitTime && isFutureDate(scope.row.visitTime)"
              type="primary"
              >{{ scope.row.visitTime }}</el-link
            >
            <el-link @click="goSet(scope.row)" v-else type="primary"
              >去设置</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="idCardNo" align="center" label="身份证号">
        </el-table-column>
        <el-table-column prop="medicalCardNo" align="center" label="医保卡号">
        </el-table-column>
        <!-- <el-table-column prop="phoneNo" align="center" label="手机号">
        </el-table-column> -->

        <el-table-column prop="age" align="center" label="年龄">
        </el-table-column>
        <el-table-column prop="gender" align="center" label="性别">
          <template #default="scope">
            <span>{{ gender_name(scope.row.gender) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="birth" align="center" label="出生日期">
        </el-table-column>
        <el-table-column prop="docName" align="center" label="管理医生">
        </el-table-column>
        <el-table-column prop="" align="center" label="报告">
          <template #default="scope">
            <el-button @click="see(scope.row, 'report')" type="text"
              >查看</el-button
            >
            <!--            <template v-if="scope.row.report" >-->
            <!--              <el-button @click="see(scope.row,'report')" type="text" >查看</el-button>-->
            <!--            </template>-->
            <!--            <template v-else>-->
            <!--              <span>&#45;&#45;</span>-->
            <!--            </template>-->
          </template>
        </el-table-column>
        <!--        <el-table-column
            prop=""
            align="center"
            label="肠道准备详情">
          <template #default="scope">
            <div class="newBox">
              <el-button @click="see(scope.row,'details')" type="text" >查看</el-button>
              <template v-if="scope.row.unreplay" >
                <p class="new_text">new!</p>
              </template>
            </div>
&lt;!&ndash;            <template v-if="scope.row.gutPrepare" >&ndash;&gt;
&lt;!&ndash;              <el-button @click="see(scope.row,'details')" type="text" >查看</el-button>&ndash;&gt;
&lt;!&ndash;            </template>&ndash;&gt;
&lt;!&ndash;            <template v-else>&ndash;&gt;
&lt;!&ndash;              <span>&#45;&#45;</span>&ndash;&gt;
&lt;!&ndash;            </template>&ndash;&gt;
          </template>
        </el-table-column>-->
        <el-table-column v-if="false" prop="" align="center" label="复诊建议">
          <template #default="scope">
            <el-button @click="see(scope.row, 'proposal')" type="text"
              >查看</el-button
            >
            <!--            <template v-if="scope.row.visitCommend" >-->
            <!--              <el-button @click="see(scope.row,'proposal')" type="text" >查看</el-button>-->
            <!--            </template>-->
            <!--            <template v-else>-->
            <!--              <span>&#45;&#45;</span>-->
            <!--            </template>-->
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="随访历史记录">
          <template #default="scope">
            <el-button @click="HistoryTime(scope.row)" type="text"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <reportDialog
      v-model="report_model"
      :list="report_list"
      :patientOpenId="patientOpenId_"
      :patientId="patientId"
    />
    <detailsDialog v-model="details_model" :list="details_list" />
    <proposalDialog
      v-model="proposal_model"
      :list="proposal_list"
      :patientOpenId="patientOpenId_"
      :patientId="patientId"
    />
    <FollowTime
      v-model="follow_time"
      :time="visitTime"
      :visitRecommend="visitRecommend"
      @saveFollow="saveFollow"
      @closed="closedFn"
    >
    </FollowTime>
    <followRecords
      v-model="follow_records"
      :list="followRecordsList"
    ></followRecords>
  </div>
</template>

<script>
import reportDialog from "@/components/TestKit/dialog/report-dialog";
import detailsDialog from "@/components/TestKit/dialog/details-dialog";
import proposalDialog from "@/components/TestKit/dialog/proposal-dialog";
import FollowTime from "@/components/TestKit/dialog/follow-time";
import followRecords from "@/components/TestKit/dialog/follow-records";
export default {
  name: "subjectAll",
  components: {
    reportDialog,
    detailsDialog,
    proposalDialog,
    FollowTime,
    followRecords,
  },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phone: "",
        idCardNo: "",
        identify: "",
        patientStatus: "",
        nextVisitTime: [],
      },
      total: 0,
      doctor_list: [],
      table: [],
      tableLoad: false,
      range: [
        {
          value: "1",
          text: "男",
        },
        {
          value: "0",
          text: "女",
        },
      ],
      report_list: [],
      patientOpenId_: "",
      patientId: "",
      report_model: false,
      details_list: [],
      details_model: false,
      proposal_list: [],
      proposal_model: false,
      patient_status: [
        {
          key: "0",
          value: "待回复",
        },
        {
          key: "1",
          value: "已回复",
        },
        {
          key: "2",
          value: "未提交",
        },
      ],
      follow_time: false,
      follow_records: false,
      visitTime: "",
      visitRecommend: "",
      patientIdApple: "",
      visitId: "",
      followRecordsList: [],
    };
  },
  computed: {
    gender_name() {
      return function(row) {
        let str;
        str = this.range
          .filter((item) => item.value === row)
          .map((item) => item.text)[0];
        return str;
      };
    },
  },
  created() {
    this.get_list();
    this.doctorlistFn();
  },
  mounted() {
    console.log("我重新部署了");
  },
  methods: {
    isFutureDate(rowTime) {
      let time = new Date().toISOString().split("T")[0];
      const target = new Date(rowTime);
      const current = new Date(time);
      return target > current;
    },
    closedFn() {
      this.visitTime = "";
      this.visitRecommend = "";
      this.patientIdApple = "";
      this.visitId = "";
    },

    saveFollow(e) {
      console.log(e);
      this.$post("report/submit", {
        patientId: this.patientIdApple,
        time: `${e.time} 00:00:00`,
        visitRecommend: e.visitRecommend,
        id: this.visitId,
      }).then((res) => {
        console.log(res);
        this.$message({
          message: "设置成功",
          type: "success",
        });
        this.get_list();
      });
    },
    goSet(row) {
      console.log(row.visitTime, this.isFutureDate(row.visitTime));
      if (row.visitTime && this.isFutureDate(row.visitTime)) {
        console.log("有时间");
        this.$router.push({
          path: "/subjectEdit",
          query: {
            info: JSON.stringify(row),
          },
        });
      } else {
        this.visitTime = row.visitTime;
        this.visitRecommend = row.visitRecommend;
        this.patientIdApple = row.patientId;
        this.visitId = row.visitId;
        console.log(this.visitTime);
        this.follow_time = true;
      }
    },
    HistoryTime(row) {
      // console.log(row);
      this.$get(`hh/polypInfo/findHistory?openId=${row.patientOpenId}`).then(
        (res) => {
          console.log(res);
          this.followRecordsList = res.data;
          this.follow_records = true;
        }
      );
    },

    formatDateToYYYYMMDD(time) {
      const date = new Date(time);

      // 提取年、月、日
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以需要加1
      const day = String(date.getDate()).padStart(2, "0");

      // 格式化为 YYYY-MM-DD
      return `${year}-${month}-${day}`;
    },
    detail(row) {
      this.$router.push({
        path: "/bowelPreparationDetails",
        query: {
          id: row.patientId,
          docId: row.docId,
        },
      });

      /*this.$post("gp/getPageNumber",{
        userId: row.patientId
      })
        .then(res => {
          if(res) {
            let data_ = res.data;

            this.$router.push({
              path: "/subjectAllDetails",
              query: {
                userId: row.patientId,
                docId: row.docId,
                pageNumber: data_,
              }
            })

          }
        })*/
    },
    see(row, str) {
      console.log(row);
      this.patientOpenId_ = row.patientOpenId;
      this.patientId = row.patientId;
      switch (str) {
        case "report":
          this.$get(`colondoc/report/${row.patientId}`).then((res) => {
            if (res) {
              let data_ = res.data;
              this.report_list = data_;
              this.report_model = true;
            }
          });

          break;
        case "details":
          this.$get("colondoc/bowlResultDetal", {
            docOpenId: row.docOpenId,
            patientId: row.patientId,
          }).then((res) => {
            if (res) {
              let data_ = res.data.map((item) => {
                item.jsonObject.picList = (item.jsonObject.picList || []).map(
                  (item_) => {
                    item_["result_"] = "";
                    item_["remark_"] = "";
                    item_["is_reply"] = true;
                    return item_;
                  }
                );
                return item;
              });
              this.details_list = data_;
              this.details_model = true;
            }
          });
          break;
        case "proposal":
          this.$get(`colondoc/visits/${row.patientId}`).then((res) => {
            if (res) {
              let data_ = res.data;
              this.proposal_list = data_.map((item) => {
                item["is_edit"] = true;
                return item;
              });
              this.proposal_model = true;
            }
          });
          break;
      }
    },
    onSubmit(y) {
      y.current = 1;
      y.pageNum=1

      console.log(y);
      this.get_list(y);
    },
    Reset() {
      this.form.nextVisitTime = [];
      this.$refs["formRef"].resetFields();
      this.$nextTick(() => {
        this.get_list();
      });
    },
    get_list() {
      // this.$get("gp/getPatientList", this.form)
      //   .then(res => {
      //     if (res) {
      //       let data_ = res.data;
      //       this.total = data_.total;
      //       this.table = data_.records;
      //     }
      //   })

      this.$post("gp/getPatientVisitInfoList", this.form).then((res) => {
        if (res) {
          let data_ = res.data;
          this.total = data_.total;

          data_.list.forEach((item) => {
            if (item.visitTime) {
              item.visitTime = this.formatDateToYYYYMMDD(item.visitTime);
            }
          });

          this.table = data_.list;
        }
      });
    },
    handleSizeChange(e) {
      this.form.pageSize = e;
      this.get_list();
    },
    handleCurrentChange(e) {
      this.form.pageNum = e;
      this.get_list();
    },
    doctorlistFn() {
      this.$get(`gp/getDoctorList/${"0"}`).then((res) => {
        if (res) {
          console.log(res);
          let data_ = res.data;
          this.doctor_list = data_;
        }
      });
    },
    add() {
      this.$router.push({
        path: "/subjectAllDetails",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/TestKit/TestKitList";
</style>
