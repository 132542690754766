<template>
    <el-dialog title="随访时间" :visible.sync="open" :close-on-click-modal="true" @closed="closedFn" width="700px">
        <template #default>
            <div class="followBox">

                <div class="FormBox">
                    <el-form label-position="right">
                        <el-form-item label-width="100px" label="设置时间：">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="form.time" style="width: 85%" size="small" type="date"
                                placeholder="请选择时间" />
                        </el-form-item>
                        <el-form-item label-width="100px" label="备注：">
                            <el-input v-model="form.visitRecommend" style="width: 85%" :rows="5" type="textarea"
                                placeholder="请输入" />
                        </el-form-item>
                    </el-form>
                </div>
                <div class="btnBox">
                    <el-button @click="preservation" style="margin-right: 30px;" type="primary">保存</el-button>
                    <el-button @click="cancel">取消</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
</template>
<script>


export default {
    name: "follow-time",
    props: {
        value: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        time: {
            type: String,
            default: () => {
                return '';
            }
        },
        visitRecommend:{
            type: String,
            default: () => {
                return '';
            }
        }
    },
    computed: {
        open: {
            get() {
                return this.value
            },
            set(v) {
                console.log(v);
                this.$emit("input", v)
            },
        },
    },
    watch: {
        time: {
            handler(newVal) {
                console.log(newVal);
                this.form.time = newVal
                console.log(this.form.time);
            },
            immediate: true,
            deep: true
        },
        visitRecommend:{
            handler(newVal) {
                console.log(newVal);
                this.form.visitRecommend = newVal
                console.log(this.form.time);
            },
            immediate: true,
            deep: true
        }
    },

    data() {
        return {
            form: {
                time: '',
                visitRecommend: '',
            },

        }
    },
    methods:{
        preservation(){

            if(!this.form.time) return this.$message.error("请选择时间")


            this.$emit("saveFollow", this.form)
            this.open = false
        },
        cancel(){
            this.open = false
        },
        closedFn(){
            // this.form.time=''
            // this.form.visitRecommend=''
            this.$emit("closed")
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/style/TestKit/TestKitDialog.scss";

.followBox {
    height: 310px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .FormBox {
        padding-left: 30px;
    }

    .btnBox {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #DCDCDC;
        box-sizing: border-box;
        padding-top: 20px;
        width: 100%;
    }
}
</style>